import React from "react";
import PropTypes from "prop-types";
import { navigate, Link, graphql } from "gatsby";
import loadable from "@loadable/component";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";
import WhiteButton from "../components/v2/WhightButton";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Hidden from "@material-ui/core/Hidden";
import requestDemoBGImg from "../images/v2/Gmail-page-footer-img.png";

// All Images
import salesManagementImg from "../images/v2/home/features-images/sales-management.svg";
import integrationImg from "../images/v2/home/features-images/integration.svg";
import securityImg from "../images/v2/home/features-images/security.svg";
import webformsImg from "../images/v2/home/features-images/webforms.svg";
import analyticsAndInsites from "../images/v2/home/features-images/analytics-and-insites.svg";
import dashboardImg from "../images/v2/home/features-images/dashboard.svg";
import leadCapture from "../images/v2/home/features-images/lead-capture.svg";
import lightBlueBgImg from "../images/v2/light-blue-bg.svg";
import remoteReadyBG from "../images/v2/home/remote-ready-bg.svg";
import responsiveBG from "../images/v2/home/responsive-bg-img.svg";
import integrationBG from "../images/v2/home/integration-bg.svg";
import crmInsightsBgImg from "../images/v2/home/crm-insights-bg.svg";
import whatsappIcon from "../images/v2/campaigns/social.png";
import whatsappMessage from "../images/v2/home/features-images/what's-app-message.svg";
import SingleTestimonial from "../components/v2/HomeSlider/SingleTestimonial";
import smeSupportLogo from "../images/v2/campaigns/smeSupportLogo.png";
import CompanyCard from "../components/v2/CompanyCard";
import whatsAppChat from "../animations/whatsAppChat.json";
import LazyLoad from "react-lazyload";
// import Lottie from "lottie-react";
import HomeCard from "../components/v2/HomeCard/HomeCard";
import tickImage from "../images/v2/whatsapp/tickIconImage.png";
import clsx from "clsx";
// import WebinarModal from "../components/v2/WebinarModal";

const VideoModal = loadable(() => import("../components/v2/VideoModal"));

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "15px",
    rowGap: "25px",
    width: "100%",
    flexWrap: "wrap",
    margin: "auto",
    maxWidth: "1280px",
    padding: "0 28px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  },
  homeSliderText: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  loginButton: {
    marginTop: theme.spacing(2),
    color: "#000000",
    border: "1px solid rgb(0 0 0 / 62%)",
    fontWeight: 600,
  },
  customButton: {
    fontSize: "22px",
    marginTop: "25px",
  },
  explorButton: {
    boxShadow:
      " 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    border: "1px solid green",
    "&:hover": {
      border: "1px solid green",
    },
  },
  clientLogoImg: {
    width: "100%",
    height: "auto",
    padding: "0 1rem",
  },
  cycleProcessImg: {
    "& picture img": {
      willChange: "auto !important",
    },
    // maxWidth: "530px",
    // height: "auto",
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  imgLogo: {
    height: "86px",
    width: "86px",
    [theme.breakpoints.down("xs")]: {
      height: "40px",
      width: "40px",
    },
  },
  crmInsightsimg: {
    maxWidth: "480px",
    height: "auto",
  },
  crmheadimg: {
    width: "100%",
    height: "auto",
  },
  crmheadimgContainer: {
    marginTop: "-55px",
    //  marginLeft: "100px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",

      backgroundImage:
        "linear-gradient(356deg, #effcff 122%, rgba(2, 145, 174, 0.32) 103%, rgba(255, 255, 255, 0) 48%)",
    },
  },
  largecrmheadimgContainer: {
    marginTop: "-55px",
    marginLeft: "100px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  crmheadingsubimg: {
    textAlign: "center",
    marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
    },
  },
  featureItemContainer: {
    listStyleType: "none",
    padding: "0px",
  },
  featureItem: {
    display: "block",
    cursor: "pointer",
    border: "2px solid",
    borderRadius: theme.shape.borderRadius * 2.5,
    borderColor: "#e7f2ff",
    lineHeight: "22px",
    padding: theme.spacing(2),
    minHeight: "370px",
    "&:hover": {
      background: "#f2f9f9",
      borderColor: "#f2f9f9",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "380px",
      padding: "1rem",
      "& h3": {
        fontSize: "22px",
      },
    },
  },
  featureItemText: {
    fontSize: "16px !important",
  },
  remoteReadyRoot: {
    background: `url(${remoteReadyBG})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
  },
  responsiveRoot: {
    background: `url(${responsiveBG}) center top`,
    backgroundSize: "cover",
    minHeight: "700px",
    paddingTop: "180px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage:
        "linear-gradient(241deg, #00b8d8 100%, #00b8d8 53%, #0893af)",
      paddingTop: "32px",
    },
  },
  integrationRoot: {
    background: `url(${integrationBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
  },
  collapseDivider: {
    backgroundColor: "#7ad2e2",
  },
  expendButton: {
    backgroundColor: "#5ab6cf",
    marginBottom: theme.spacing(1),
    "&:hover": {
      background: "#a1dae2",
    },
  },
  numberBorder: {
    borderRight: "1px solid #e5e7e9",
  },
  contactUsCard: {
    boxShadow: "0 15px 50px 0 rgba(118, 130, 183, 0.16)",
    padding: theme.spacing(3),
    borderRadius: 10,
    border: "solid 1px #e2f0ff",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5),
    },
  },
  blogCard: {
    boxShadow: "0 15px 20px 0 rgba(118, 130, 183, 0.18)",
    borderRadius: 5,
    padding: theme.spacing(2),
    marginTop: -2,
  },
  IntegratedCRMBg: {
    background: "linear-gradaent(to bottom, #ffffff 0%, #e7f2fe 100%)",
  },
  ConnectCrm: {
    background: "linear-gradient(to bottom, #ffffff 0%, #e7f2fe 100%)",
  },
  WhatsAppCrm: {
    background: "linear-gradient(1deg, #dbe9f9 30%, #fff 117%)",
  },
  svg: {
    "& svg g g rect": {
      fill: "none",
    },
  },
  lightBlueBg: {
    background: `url(${lightBlueBgImg}) center top`,
  },
  crmInsightsBg: {
    // background: `url(${crmInsightsBgImg}) left top no-repeat`,
    background: "linear-gradient(179deg, #eaf4fe -6%, #fff 40%)",
  },
  iconContainer: {
    display: "flex",
    columnGap: "100px",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      columnGap: "10px",
      marginLeft: "29px",
      marginTop: "-64px",
    },
  },
  smeSupportImg: {
    width: "461.2px",
    height: "79.3px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
    },
  },
  formSubmit: {
    fontSize: "16px",
    fontWeight: "600",
  },
  listItemText: {
    "& *": {
      fontSize: 20,
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 36,
    backgroundColor: "#08a742",
    borderRadius: "50%",
    padding: "5px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      marginRight: theme.spacing(1.5),
    },
  },
  sliderTestimonialImg: {
    boxShadow: "7px 20px 20px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "10px",
  },
  cimg: {
    "& .gatsby-image-wrapper": {
      filter: "grayscale(100%)",
      "&:hover": {
        filter: "none",
      },
    },
  },
  link: {
    cursor: "pointer",
  },
  getStartedBtn: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    "& .primaryButton": {
      padding: "15px 40px",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  videoContainer: {
    position: "relative",
  },
  "@keyframes smallScale": {
    from: {
      transform: "scale(1)",
      opacity: 1,
    },
    to: {
      transform: "scale(1.5)",
      opacity: 0,
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  "@keyframes triangleStroke": {
    from: {
      strokeDashoffset: 90,
    },
    to: {
      strokeDashoffset: 0,
    },
  },

  videoBtnCircle: {
    cursor: "pointer",
    position: "absolute",
    display: "flex",
    backgroundColor: "#4cbb83",
    borderRadius: "50%",
    width: "100px",
    height: "100px",
    padding: "0px",
    top: "62.5%",
    left: "35.5%",
    zIndex: "2",
    "&:before, &:after": {
      content: '""',
      background: "rgba(76, 187, 131, 0.5)",
      borderRadius: "50%",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      width: "100%",
      animationName: "$smallScale",
      animationDuration: "3s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
    },
    "&:after": {
      animationDelay: "0.5s",
      zIndex: -1,
    },
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "30px",
    height: "30px",
    transform: "translate(-50%, -50%)",
    "& .triangle": {
      animation: `$fadeIn 1.5s ${theme.transitions.easing.easeIn}`,
    },
    "& .path": {
      strokeDasharray: 90,
      strokeDashoffset: 0,
      animation: "$triangleStroke 1.5s",
      animationTimingFunction: `${theme.transitions.easing.easeIn}`,
    },
  },
  hasScaleAnimation: {
    animationName: "$smallScale",
    animationDuration: "3s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  bold: {
    fontWeight: "700",
    color: "#333333",
  },
  boostText: {
    paddingBottom: "21px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
  boostTextwidth: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up(1120)]: {
      width: "1120px",
    },
  },
  nopadding: {
    display: "block",
    // marginRight: "-76px",

    [theme.breakpoints.up(1200)]: {
      display: "flex",
      marginRight: "-76px",
      paddingLeft: "40px",
    },
  },
  nopad: {
    paddingLeft: "0",
    paddingTop: "0",
  },
  nopads: {
    paddingBottom: "24px",
  },

  nopadtpbtm: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  listPadding: {
    whiteSpace: "pre",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
  listFourpadding: {
    paddingLeft: "56px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  colorChange: {
    color: "#ff7a59",
    fontSize: "30px",
    fontWeight: "700",
    letterSpacing: ".4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  whitespaceNowrap: {
    whiteSpace: "nowrap",
  },
  requestDemoRoot: {
    background: `linear-gradient(90deg, #011D32 0%, #00182B 100%)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "60px 0",
  },
  smeBannerTxts: {
    padding: "3px",
    margin: "0",
    marginLeft: "5px",
    paddingBottom: "24px",
    marginLeft: "auto",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-18px",
      lineHeight: "30px",
      paddingBottom: "24px",
      marginLeft: "auto",
    },
  },
  listItemTextWhite: {
    "& *": {
      fontSize: 20,
      color: "#fff",
      whiteSpace: "noWrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 15,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 18,
      },
    },
  },
  pageHeader: {
    width: "650px",

    // margin: "150.2px 36px 33.8px 74.5px",
    objectFit: "contain",
    fontFamily: "Poppins",
    fontSize: "40px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.8px",
    textAlign: "left",
    color: "#000",
    marginTop: "-100px",

    [theme.breakpoints.down("sm")]: {
      width: "auto",
      // margin: '0 21px 21px',
      fontSize: "20px",
      textAlign: "center",
      letterSpacing: "0.4px",
      marginLeft: "30px",
    },
  },
  subText: {
    width: "674px",

    // margin: '33.8px 99px 27.8px 74.5px',
    objectFit: "contain",
    // fontFamily: 'SourceSansPro',
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.56",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      // margin: '21px 16px 18.2px 17px',
      fontSize: "16px",
      textAlign: "center",
      marginLeft: "30px",
    },
  },
  topsection: {
    background: "linear-gradient(to left, rgba(2, 145, 174, 0.32), #effcff)",
    [theme.breakpoints.down("sm")]: {
      background: "white",
    },
  },
  footerButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    margin: "20px 0",
    "& .primaryButton": {
      padding: "15px 40px",
    },
  },
}));

const listOne = `Share catalogs with ease
Send bulk promotions
Chat widget on website`.split(`
`);

const listTwo = `Auto-reply with message templates
Single number for team
Assist customers with AI chatbot`.split(`
`);

const listThree = `Facebook integration
Instagram integration
Telegram integration
Sales management
Sales forecasting`.split(`
`);

const listFour = `Lead  management
Workflow automation
Audio and video calls
Customised dashboard
Integrated with various tools`.split(`
`);

const listFive = `Up to 50% PSG grant
24/7 Local support
No hidden charges
Built for Singapore SMEs`.split(`
`);

const dummyData = [
  {
    id: 1,
    image: "../images/green-grad.png",
    backgroundColor: "#f0f0f0",
    link: "/crm-integrations/aircall-voice-call-integration",
    badgeText: "Calling",
    badgeColor: "#cf2bd5",
    badgeBackground: "#efdbfa",
    heading: "Aircall",
    description:
      "Initiate and manage voice calls with CRM Aircall integration.",
  },
  {
    id: 29,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Data management",
    badgeColor: "#be6e51",
    badgeBackground: "#f1e4e0",
    link: "/crm-integrations/confluence-knowledge-base-software",
    heading: "Confluence",
    description:
      "Enrich AssistAI knowledge base with CRM Confluence integration.",
  },
  {
    id: 2,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Inventory management",
    badgeColor: "#26c031",
    badgeBackground: "#cafece",
    link: "/crm-integrations/cin7-dear-inventory",
    heading: "Dear Systems",
    description:
      "Track sales in real-time with Cin7 (Formerly Dear Inventory) and CRM integration.",
  },

  {
    id: 3,
    image: "../images/blue-grad.png",
    backgroundColor: "#f0f0f0",
    link: "/crm-integrations/facebook-lead-gen-ads-form",
    badgeText: "Lead capture",
    badgeColor: "#c7960b",
    badgeBackground: "#fff2d8",
    heading: "Facebook lead form",
    description: "Capture and track leads with Facebook lead form integration.",
  },
  {
    id: 4,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Messaging",
    badgeColor: "#f3296b",
    badgeBackground: "#ffdae6",
    link: "/crm-integrations/best-facebook-messenger-crm-integration",
    heading: "Facebook Messenger",
    description: "Manage Facebook chats with Messenger integration.",
  },
  {
    id: 5,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Email inbox",
    badgeColor: "#2635c0",
    badgeBackground: "#dadfff",
    link: "/crm-integrations/gmail-email-inbox",
    heading: "Gmail",
    description: "Sync and send emails with CRM Gmail integration.",
  },
  {
    id: 6,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Calendar",
    badgeColor: "#6a42bd",
    badgeBackground: "#f4e1ff",
    link: "/crm-integrations/google-calendar-integration",
    heading: "Google Calender",
    description: "Book meetings quickly with Google Calendar integration.",
  },
  {
    id: 7,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Contacts",
    badgeColor: "#f38129",
    badgeBackground: "#fde3d0",
    link: "/crm-integrations/google-contacts-integration",
    heading: "Google Contacts",
    description: "Sync and manage contacts with Google Contacts integration. ",
  },
  {
    id: 8,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Data management",
    badgeColor: "#be6e51",
    badgeBackground: "#f1e4e0",
    link: "/crm-integrations/google-drive-integration",
    heading: "Google Drive",
    description: "Store and retrieve documents with Google Drive integration.",
  },
  {
    id: 9,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    link: "/crm-integrations/google-form",
    badgeText: "Lead capture",
    badgeColor: "#2d8cff",
    badgeBackground: "#cee3ff",
    heading: "Google Forms",
    description: "Record and manage data with CRM Google Forms integration.",
  },
  {
    id: 26,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Data management",
    badgeColor: "#be6e51",
    badgeBackground: "#f1e4e0",
    link: "/crm-integrations/google-sheets",
    heading: "Google Sheet",
    description:
      "Enhance business efficiency with Google Sheets CRM integration.",
  },
  {
    id: 10,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Messaging",
    badgeColor: "#f3296b",
    badgeBackground: "#ffdae6",
    link: "/crm-integrations/instagram-crm-for-sales",
    heading: "Instagram",
    description: "Close deals quickly  on Instagram with CRM integration.",
  },
  {
    id: 25,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Task management",
    badgeColor: "rgb(11, 173, 167)",
    badgeBackground: "rgb(207, 248, 246)",
    link: "/crm-integrations/project-management-jira-integration",
    heading: "Jira",
    description:
      "Streamline tasks and customer relationships with Jira CRM integration.",
  },
  {
    id: 28,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Calling",
    badgeColor: "#cf2bd5",
    badgeBackground: "#efdbfa",
    heading: "KrispCall",
    link: "/crm-integrations/krispcall-voip-phone-system",
    description:
      "Make, manage, and track crystal clear voice calls directly within the CRM with CRM telephony integration.",
  },
  {
    id: 11,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Lead generation",
    badgeColor: "#f3296b",
    badgeBackground: "#ffdae6",
    link: "/crm-integrations/linkedin-social-media",
    heading: "LinkedIn",
    description: "Capture and manage leads via LinkedIn CRM integration.",
  },
  {
    id: 12,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Email marketing",
    badgeColor: "#0bada7",
    badgeBackground: "#cff8f6",
    link: "/crm-integrations/mailchimp-crm-with-email-marketing",
    heading: "Mailchimp",
    description: "Optimise email marketing with Mailchimp CRM integration.",
  },
  {
    id: 13,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Email marketing",
    badgeColor: "#0bada7",
    badgeBackground: "#cff8f6",
    link: "/crm-integrations/mailerlite-crm-with-email-marketing",
    heading: "MailerLite",
    description: "Enhance email marketing with CRM MailerLite integration.",
  },
  {
    id: 14,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Data management",
    badgeColor: "#be6e51",
    badgeBackground: "#f1e4e0",
    link: "/crm-integrations/onedrive-integration",
    heading: "OneDrive",
    description: "Store and retrieve files on OneDrive with CRM integration.",
  },
  {
    id: 15,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Email inbox",
    badgeColor: "#8f0bc7",
    badgeBackground: "#f0ceff",
    link: "/crm-integrations/microsoft-outlook-365-integration",
    heading: "Outlook",
    description: "Sync and send emails with CRM Outlook integration.",
  },
  {
    id: 16,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Calendar",
    badgeColor: "#6a42bd",
    badgeBackground: "#f4e1ff",
    link: "/crm-integrations/office365-calendar-integration",
    heading: "Outlook Calender",
    description:
      "Track upcoming sales activities with Outlook Calendar integration.",
  },
  {
    id: 17,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    link: "/crm-integrations/microsoft-outlook-contacts-integration",
    badgeText: "Contacts",
    badgeColor: "#f38129",
    badgeBackground: "#fde3d0",
    heading: "Outlook Contacts",
    description: "Sync and manage contacts with Outlook Contacts integration.",
  },

  {
    id: 18,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Accounting",
    badgeColor: "#2d8cff",
    badgeBackground: "#cee3ff",
    link: "/crm-integrations/quickbooks-accouting-tool",
    heading: "QuickBooks",
    description: "Generate and track estimations with QuickBooks integration.",
  },
  {
    id: 19,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Lead generation",
    badgeColor: "#f3296b",
    badgeBackground: "#ffdae6",
    link: "/crm-integrations/shopify-ecommerce-integration",
    heading: "Shopify",
    description: "Sync, manage, and track orders with CRM Shopify integration.",
  },
  {
    id: 20,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Messaging",
    badgeColor: "#f3296b",
    badgeBackground: "#ffdae6",
    link: "/crm-integrations/telegram-social-media",
    heading: "Telegram",
    description:
      "Best CRM with Telegram Integration To Maximize Sales | Pepper Cloud",
  },
  {
    id: 21,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Messaging",
    badgeColor: "#f3296b",
    badgeBackground: "#ffdae6",
    link: "/crm-integrations/whatsapp-business-api",
    heading: "WhatsApp Cloud API",
    description:
      "Easily drive communication using CRM integration with WhatsApp Business Cloud API.",
  },
  {
    id: 24,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Messaging",
    badgeColor: "#f3296b",
    badgeBackground: "#ffdae6",
    link: "/crm-integrations/whatsApp-via-twilio",
    heading: "WhatsApp Twilio API",
    description: "Improve engagement with WhatsApp Business API integration.",
  },
  {
    id: 27,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Messaging",
    badgeColor: "#f3296b",
    badgeBackground: "#ffdae6",
    link: "https://peppercloud.com/whatsapp-crm",
    heading: "WhatsApp CRM",
    description: "Sell, supervise, and support with WhatsApp CRM integration.",
  },
  {
    id: 22,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    link: "/crm-integrations/xero-accounting-software",
    badgeText: "Accounting",
    badgeColor: "#2d8cff",
    badgeBackground: "#cee3ff",
    heading: "Xero",
    description: "Generate and track quotations with Xero CRM integration.",
  },
  {
    id: 23,
    image: "https://via.placeholder.com/150",
    backgroundColor: "#f0f0f0",
    badgeText: "Video calling",
    badgeColor: "#26c031",
    badgeBackground: "#cafece",
    link: "/crm-integrations/zoom-meetings-for-video-conferencing",
    heading: "Zoom",
    description:
      "Schedule and manage video meetings with CRM Zoom integration.",
  },
];

const client = {
  image: (
    <StaticImage
      alt="Pepper Cloud+TechData Corporation"
      layout="constrained"
      src="../images/v2/testimonials-images/yoon-kam-fei-peppercloud-customer.png"
    />
  ),
  name: "Yoon Kam Fei",
  position: "Managing Director",
  company: "Tec D Distribution Sdn Bhd, Malaysia",
  content: (
    <React.Fragment>
      Team collaboration, accurate sales forecasting, and effective resource
      management are the challenges every organisation encounters. But having an
      efficient CRM tool like Pepper Cloud enhances productivity while assisting
      in optimising the sales process and managing the sales pipeline.
      <br />
      <br />
      Pepper Cloud CRM tool is the right mix of flexibility, affordability, and
      ease of use.
    </React.Fragment>
  ),
};

const features = [
  {
    title: "Messaging",
    description:
      "Connect and communicate with your leads effortlessly. With our multichannel integration, converse from a single platform. Assign chats to the team members as per the need and provide personalised responses with our Facebook and Whatsapp integration.",
    logo: whatsappMessage,
    href: "/channel",
    alt: "WhatsApp CRM system",
  },
  {
    title: "Lead capture",
    description:
      "Capture your leads directly into the CRM software from integrated web forms or digital ads. Automatically, add them to your sales pipeline and convert them into your customers while cutting down on repetitive tasks.",
    logo: leadCapture,
    href: "/webform",
    alt: "CRM system to capture sales leads",
  },
  {
    title: "Sales management",
    description:
      "Organise your sales funnel and stay in control of your business  with easy customisable sales pipelines. Gain complete visibility into sales activities and drive your team towards winning opportunities. Manage all your sales data from a unified platform and forecast your future revenue.",
    logo: salesManagementImg,

    href: "/sales-pipeline",
    alt: "Sales growth with the best CRM system",
  },
  {
    title: "Integrations",
    description:
      "Integrate with your favourite apps and bring harmony to your daily activities. With plug-and-play integrations of Pepper Cloud, sync your marketing tools, calendars, emails, accounting software, video conferencing tools, messaging apps, and more.",
    logo: integrationImg,
    href: "/integration",
    alt: "Sales with CRM system integration",
  },
  {
    title: "Analytics and insights",
    description:
      "Get a bird’s eye view of your business performance with an interactive dashboard. Customise it at your convenience and access in-depth insights on your leads, activities, and campaigns.",
    logo: analyticsAndInsites,
    href: "/dashboard",
    alt: "Sales CRM dashboard",
  },
  {
    title: "Security",
    description:
      "Gain absolute data protection and security control through vulnerability-tested CRM software that offers data encryption, user access management, and two-factor authentication.",
    logo: securityImg,
    href: "/security",
    alt: "Secured CRM software",
  },
];

// Avoid duplication or unnecessary data entry.
// Understand the reasons for losing opportunities by analysing your processes and sales strategies.
// Identify your staff training needs by reviewing your sales process, and the associated activities.

const security =
  `Sync your communication channels and expedite sales with instant messaging.
Arm your team with the CRM system to win more opportunities with the least manual work.
Track all the tasks and associated activities and refocus their efforts when necessary. 
Instantly acknowledge the top performers of your team and derive the best practices for everyone.
Reason your won and lost opportunities by analysing your processes and sales strategies.`
    .split(`
`);

const Home = (props) => {
  const width = useWidth();
  const classes = useStyles();

  const [showVideo, setShowVideo] = React.useState(false);
  const [activePipeline, setActivePipeline] = React.useState(0);

  const pipeline = React.useMemo(() => {
    const {
      data: { featureEngage, featureManage, featureAutomate, featureExpand },
    } = props;
    const pipeline = [
      {
        title: "Engage",
        description: (
          <>
            Engage effectively with your prospects and customers by sending
            emails and conversing through WhatsApp and Facebook, right from your
            CRM system. Track and record the activities by keeping a timeline
            view of every interaction with your clients. Add, edit, and complete
            new sales activities on the go.
          </>
        ),
        img: getImage(featureEngage),
        alt: "Engage more customer with best CRM with social media integration",
      },
      {
        title: "Manage",
        description: (
          <>
            Manage your business with an efficient{" "}
            <a href="https://peppercloud.com/sales-pipeline">
              {" "}
              sales pipeline management
            </a>{" "}
            feature. Build multiple sales pipelines and customise stages in the
            pipelines as per your needs. Identify, add, and categorise new leads
            easily and quickly. Save time and team efforts by eliminating
            repetitive tasks. Stay on top of your activities and follow-ups with
            utmost convenience.
          </>
        ),
        img: getImage(featureManage),
        alt: "Manage business with sales pipeline management tool",
      },
      {
        title: "Automate",
        description: (
          <>
            With{" "}
            <a href="https://peppercloud.com/integration">
              {" "}
              powerful CRM system integrations
            </a>
            , sync your favourite tools to capture leads automatically, generate
            quotations, set up video meetings, and keep track of your processes
            and activities in real-time. Eliminate repetitive tasks with
            ultimate CRM capabilities. Automate your sales progress with the
            Pepper Cloud CRM tool.
          </>
        ),
        img: getImage(featureAutomate),
        alt: "Automate everything with powerful CRM system integrations",
      },
      {
        title: "Expand",
        description:
          "Increase sales referrals with the help of existing customers. Improve products and services with the help of your customers’ feedback. Discover opportunities to upsell and cross-sell to your existing customers with the help of existing customer information and actionable in-depth insights.\n",
        img: getImage(featureExpand),
        alt: "Expand business with Best Sales CRM",
      },
    ];

    return pipeline;
  }, []);

  const toggleVideoModal = () => setShowVideo((st) => !st);

  return (
    <React.Fragment>
      <SEO
        description="With powerful CRM system integrations, integrate Gmail, Outlook. Mailchimp, Google drive, and other apps and boost your sales growth multifold."
        jsonLd={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Pepper Cloud",
          legalName: "Pepper Cloud PTE LTD",
          url: "https://peppercloud.com/",
          logo: "https://peppercloud.com/icons/logo.png",
          foundingDate: "2017",
          address: [
            {
              "@type": "PostalAddress",
              streetAddress: "#04-01/02",
              addressLocality: "Blk 79 Ayer Rajah Crescent",
              addressRegion: "SG",
              postalCode: "139955",
              addressCountry: "Singapore",
            },
            {
              "@type": "PostalAddress",
              streetAddress:
                "2nd Floor, Salarpuria Tower 1, 7th Block Koramangala",
              addressLocality: "Bengaluru",
              addressRegion: "KA",
              postalCode: "560095",
              addressCountry: "India",
            },
          ],
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            telephone: "+6566562616",
            email: ["sales@peppercloud.com", "support@peppercloud.com"],
          },
          sameAs: [
            "https://twitter.com/peppercloudpte",
            "https://www.linkedin.com/company/peppercloud/",
            "https://www.facebook.com/PepperCloudPteLtd",
            "https://www.instagram.com/pepper.cloud/",
          ],
        }}
        keywords="Best sales CRM , Singapore CRM , CRM system , CRM software"
        ogData={{
          "og:image": [
            "/meta/og/1X1/home.png",
            "/meta/og/1024X512/home.png",
            "/meta/og/1200X630/home.png",
          ],
        }}
        title="CRM system Integrations | Pepper Cloud CRM"
      />
      <Box pt={8} className={classes.topsection}>
        <Container>
          <Grid alignItems="center" container justify="center" spacing={7}>
            {/*<Box alignItems="center" component={Grid} display="flex" flexDirection="column" item md={6} sm={12}>*/}
            <Grid item lg={5} md={5} sm={12}>
              <Box className={classes.homeSliderText}>
                <Box
                  alignItems="center"
                  display="flex"
                  height={24}
                  mb={2}
                  mt={2}
                ></Box>
                <HeaderTypography
                  component={"h1"}
                  className={classes.pageHeader}
                >
                  Supercharge sales success with CRM system integrations
                </HeaderTypography>

                <ParagraphTypography className={classes.subText}>
                  Conquer your sales goals with Pepper Cloud CRM's seamless
                  integration ecosystem. Connect with various communication
                  tools, email marketing platforms, contact management
                  solutions, accounting software, and more.
                  <br />
                </ParagraphTypography>
                <Box className={classes.getStartedBtn} mb={7}>
                  <a className="primaryButton font-18 mt40" href="/contact">
                    Get started
                  </a>
                  {/* <Link to="/contact">
                    <Button
                      className={classes.customButton}
                      color="secondary"
                      // component={Link}
                      size="large"
                      variant="contained"
                    >
                      Get started
                    </Button>
                  </Link>

                  {/* <Hidden smUp>
                    <Box>
                      <Button
                        className={classes.loginButton}
                        color="secondary"
                        // fullWidth
                        href="https://app.peppercloud.com"
                        rel="noreferrer"
                        size="large"
                        target="_blank"
                        variant="outlined"
                      >
                        Login
                      </Button>
                    </Box>
                  </Hidden> */}
                </Box>
              </Box>
            </Grid>
            <Grid
              component={Box}
              item
              lg={7}
              maxWidth="100%"
              md={7}
              paddingLeft="20px"
              sm={12}
            >
              <Hidden smUp>
                <Box className={classes.crmheadimgContainer}>
                  <StaticImage
                    alt="CRM Integrations"
                    className={classes.crmheadimg}
                    placeholder="blurred"
                    src="../images/v2/crm-integration/Group 17886@2x.png"
                  />
                </Box>
              </Hidden>
              <Box className={classes.largecrmheadimgContainer}>
                <StaticImage
                  alt="CRM Integrations"
                  className={classes.crmheadimg}
                  placeholder="blurred"
                  src="../images/v2/home/Mask Group 10778.png"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box mb={2} mt={1}>
          <Divider light />
        </Box>
      </Box>

      {/* <Box mt={1} mb={0}>
        <Divider light />
      </Box> */}

      {/* <Grid
        container
        spacing={2}
        justify="center"
        style={{ maxWidth: "80vw", margin: "0 auto" }}
      > */}
      <Box className={clsx(classes.cardContainer, "MuiContainer-maxWidthLg")}>
        {dummyData.map((cardData) => (
          // <Grid item xs={12} sm={6} lg={3} xl={3} key={cardData.id} spacing={1}>

          <HomeCard
            key={cardData.id}
            image={cardData.image}
            backgroundColor={cardData.backgroundColor}
            link={cardData.link}
            badgeText={cardData.badgeText}
            badgeColor={cardData?.badgeColor}
            badgeBackground={cardData?.badgeBackground}
            heading={cardData.heading}
            description={cardData.description}
            logo={cardData.logo} // Pass logo path here
            id={cardData.id}
          />
          // </Grid>
        ))}
      </Box>
      {/* </Grid> */}
      <Box>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          my={{ md: 7 }}
          textAlign="center"
        ></Box>
      </Box>

      <Box className={classes.requestDemoRoot} py={10} textAlign="center">
        <Container>
          <Box alignItems="center" display="flex" justifyContent="center">
            <HeaderTypography
              color="common.white"
              fontSize={35}
              fontWeight={600}
              my={0}
              textAlign="center"
              component="h2"
              className={classes.boostText}
            >
              Navigate the future of business with our
              <br />
              intuitive CRM system integrations
            </HeaderTypography>
          </Box>
          <Grid item md={10} sm={12}>
            <Box className={classes.boostTextwidth}>
              <List className={classes.nopadding} pl={0}>
                {listFive.map((each) => (
                  <ListItem
                    alignItems="center"
                    className={classes.nopad}
                    key={each}
                  >
                    <ListItemIcon className={classes.tickImages}>
                      <Box alt="Check icon" component={"img"} src={tickImage} />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemTextWhite}>
                      {each}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          <Box mt={6} className={classes.footerButtonContainer}>
            <a className="primaryButton font-18" href="/contact">
              Get started
            </a>
            {/* <Link to="/contact">
              <WhiteButton
                color="primary"
                mb={6}
                // onClick={executeScroll}
                size="large"
                variant="contained"
              >
                Get started
              </WhiteButton>
            </Link> */}
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export const query = graphql`
  query {
    featureEngage: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-engage@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    featureManage: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-manage@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    featureExpand: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-expand@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    featureAutomate: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-automate@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
  }
`;

Home.propTypes = {
  data: PropTypes.object,
};

export default Home;
